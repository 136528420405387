.facetContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .browseValueContainer {
    display: flex;
    flex-direction: column;
    height: 100%;  /* Make sure it fills up the entire height of its parent */
}

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Align items vertically in the center */
  background: white;
  margin-bottom: 10px;
}

.maskAndButton {
  flex: 1; /* Allow it to grow and take up available space */
  display: flex; /* Make it flex to align its children horizontally */
  align-items: center; /* Align its children vertically in the center */
  gap: 5px; /* Creates space between its children */
}

.sortButtons {
  display: flex;
  gap: 5px;
  flex-shrink: 0; /* Prevent this container from shrinking */
}

.content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;  /* This will allow it to grow and fill the remaining space */
}

  .facetName {
    font-weight: 550;
  }

  .maskLabel {
    display: inline-block;
  }

  .mask {
    display: inline-block;
    margin-left: 10px;
    width: 200px;
    height: 22px;
    font-size: 16px;
  }

  .clearMaskButton
  {
    display: inline-block;
  }

   .selectedItems {
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
    margin-bottom: 5px;
   }

   .facetValue, .facetValue * {
    cursor: pointer;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #000;
    width: 60vw;   /* 60% of viewport width */
    height: 90vh;  /* 60% of viewport height */
    /* overflow: hidden; to hide the scrollbars */
    display: flex;
    flex-direction: column; /* Add this line */
    justify-content: space-between; /* Add this line */
    z-index: 101;
  }  

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; /* Put it behind the modal */
}

.header {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.main {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; /* Add this line */
  overflow: hidden; /* Add this line */
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-right: 18px
}