.User {
  padding: 10px;
  background-color: #f8f8f8;
  border: 0.25px solid #888888;
  display: flex;             /* Enable flex on the container */
  align-items: center;       /* Center items vertically, in cross axis */
  justify-content: center;   /* Center items horizontally, in main axis */
}

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #000;
    width: 60vw;   /* 60% of viewport width */
    height: 90vh;  /* 60% of viewport height */
    overflow: hidden; /* to hide the scrollbars */
    flex-direction: column; /* Add this line */
    justify-content: space-between; /* Add this line */
    z-index: 101;
  }  

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; /* Put it behind the modal */
}

.header {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.main {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
  
.footer {
    width: 100%;
    position: absolute;
    bottom: 10px;
    margin-top: 20px;
    right: 0px;
    text-align: right;
    padding: 0 20px; /* Add padding to match the AdvancedSearch footer */
  }

  .row {
    display: flex; 
    align-items: center;
    padding-bottom: 10px;
  }

.userMenuResults {
    position: fixed;
    border: 2px solid #d8d8d8;
    border-radius: 2px;
    background-color: #f8f8f8;
    min-width: 150px;
    z-index: 10;  
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  
  .userMenuItem {
    display: block;
    cursor: pointer;
    padding: 12px;
  }
  
  .userMenuItem:hover {
    color: #f8f8f8;
    background-color: rgb(85, 120, 224);
  }
  
  .message {
    position: fixed;
    background-color: rgba(57, 181, 75, 0.7); /* Lighter shade of the green with some transparency */
    border: 1px solid rgba(57, 181, 75, 0.9); /* Slightly darker and translucent green for the border */
    border-radius: 2px;
    right: 20px;
    top: 20px;
    padding: 12px;
    z-index: -1000;
    text-align: center;
    color: #ffffff;
    z-index: 1000000;  
    opacity: 0; /* set initial opacity to 0 */
    transition: opacity 0.3s ease-in-out; /* transition effect */
  }
    
  .fadeIn {
    opacity: 1;
    z-index: 1000;
  }
  
  .fadeOut {
    opacity: 0;
    z-index: -1000;
  }

.userdata {
  position: relative;
  display: inline-block;
}

.realname {
  display: inline-block;
}

.menubutton {
  display: inline-block;
  border: 0px;
  background-color: transparent;
  line-height: 10px;
}


