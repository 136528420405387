.Navigator {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}

.Paginator {
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
    .Paginator select {
      margin: 0 10px;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 14px;
    }
    
    .paginateButton {
      width: 20px;
      height: 18px
    }
  
    .CurPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .currentPage {
        cursor: pointer;
    }
    
    .currentPage:hover {
        text-decoration: underline;
    }

    .ResultSetSlider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }