.homePageBlock {
    display: inline-block;
    margin-right: 0px;
    text-align: center;
    background-color: #ffffff;
    cursor: pointer;
    border: 0.2px solid gray;
}

.homePageBlockImage {
    vertical-align: top;
}

.homePageBlock img {
    width: 350px;
    max-width: 100%;
}

.homePageBlockText {
    text-decoration: none;
    color: #333;
    display: inline-block;
    vertical-align: top;
    padding: 18px;
}