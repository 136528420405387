.CopyHyperlinkButton {
}

.message {
  position: fixed;
  background-color: rgba(57, 181, 75, 0.7); /* Lighter shade of the green with some transparency */
  border: 1px solid rgba(57, 181, 75, 0.9); /* Slightly darker and translucent green for the border */
  border-radius: 2px;
  right: 20px;
  top: 20px;
  padding: 12px;
  text-align: center;
  z-index: -1000;
  color: #ffffff;
  z-index: 1000000;  
  opacity: 0; /* set initial opacity to 0 */
  transition: opacity 0.3s ease-in-out; /* transition effect */
}


.fadeIn {
  opacity: 1;
  z-index: 1000;
}

.fadeOut {
  opacity: 0;
  z-index: -1000;
}