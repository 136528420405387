.EmbedCount {
    display: inline;
    white-space: nowrap;
}

.count {
    cursor: pointer;
}

.count:hover {
    text-decoration: underline;
}