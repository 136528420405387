.MobileSearch {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    font-size: 1.2rem;
}

.header {
    margin: 10px 5px;
    height: 50px;
    background-size: contain;
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    align-items: center;
    position: relative;
}

.header_logo {
    cursor: pointer;
    display: flex; /* Added to align logo elements horizontally */
    align-items: center; /* Vertically center elements */
}

.header_logo_text {
    font-family: "robust";
    text-transform: uppercase;
    display: block;
    font-size: 1.2rem;
    line-height: 1;
    width: 100%;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
}

.header_logo_svg {
    margin-left: 15px;
}

.topRightButton {
    display: inline-block;
    position: absolute;
    right: 10px; /* Position it to the right side */
    margin-left: 15px;
}

.searchbox .searchInputContainer {
    display: flex;
    align-items: center;
}

.searchbox .searchInputContainer input[type="text"] {
    flex: 1;
    margin-right: 10px;
}

.searchbox {
    margin: 0px 20px;
}

.searchbox select,
.searchbox input[type="text"],
.searchbox button {
    font-size: 1.4rem;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 5px 0;
    width: 100%;
}

.searchbox button {
    background-color: rgb(181, 52, 29);
    color: #fff;
    border: none;
    cursor: pointer;
}

.searchbox button:hover {
    background-color: rgb(181, 52, 29);
}

.browseValues,
.mobileResults {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    margin: 0px 20px;
}

.browseValues.show,
.mobileResults.show {
    min-height: 100vh;
    height: auto;
    flex: 1;
    overflow: visible;
    opacity: 1;
}

.modalMap {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 15;
    overflow: hidden;
    background: white;
}

.map {
    margin: 0px;
    width: 100%;
    height: 100%;
}

.closeButton {
    position: absolute;
    z-index: 10;
    top: 5px;
    left: 5px;
}

.infoButton {
    position: absolute;
    bottom: 80px;
    left: 10px;
    padding: 8px;
    width: 35px;
    height: 35px;
    background-color: rgba(181, 52, 29, 0.6);
    border-radius: 50%;
    border: 2px solid white;
    fill: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrollToTop {
    position: absolute;
    bottom: 80px;
    right: 10px;
    background-color: rgba(216, 216, 216, 0.5);
    color: #856872;
    padding: 4px 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 2px;
    z-index: 1000;
    transition: opacity 1s;
    display: none; /* Hide by default */
  }
  .scrollToTop.show {
    display: block; /* Show when .show is added */
  }

  