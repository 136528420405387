.masonryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.rowContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px; /* Adjust spacing as needed */
}

.iconItem, .listItem {
  flex: 1 0 21%; /* Adjust percentage as needed for desired item width */
  max-width: 160px; /* Adjust width as needed */
  height: auto; /* Ensure responsive height */
  box-sizing: border-box;
}

/* Container Styles */
.container {
  width: 100%;
}


.columnsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* This replaces the padding in the column and provides spacing between columns */
  justify-content: center;
}

.column {
  flex: 0; /* This will make all columns equally share the available space */
  width: 180px;
  height: 120px;
}

.loaderContainer {
  position: relative;
  width: 100%;
  text-align: center;
}

.loader {
  text-align: center;
}

.record {
  display: inline;
}

/* List View Record Styles */
.ccListviewRecord {
  page-break-inside: avoid !important;
  /* Reset the <a> tag to avoid it appearing like a hyperlink */
  text-decoration: none;
  color: inherit;
}

.ccListviewRecord:hover {
  /* If you still want the underline on hover for the whole block, keep this */
  text-decoration: none; 
}

.ccListviewRecord .panel-title {
  /* cursor: pointer; */ /* Commented out unless you want a clickable appearance */
  color: #333; /* or whatever color you want that isn't hyperlink blue */
}

.ccListviewRecordTable {
  border-spacing: 0px;
  width: 100%;
}

.listViewPanel {
  border: 1px solid darkgray;
  border-radius: 2px;
  padding: 0px;
  background-color: #ffffff;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
}

.ccListviewRecordImage {
  display: inline-block;
  width: 160px;
  margin-right: 15px;
}

.ccListviewRecordImage img {
  vertical-align: top;
}

.ccListviewRecordText {
  display: inline-block;
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

/* Other Styles */

.taglabel {
  font: normal 1em "National-Book", sans-serif;
  font-weight: 700;
}

a {
  text-decoration: none;
}

.hl {
  text-decoration: none;
  color: #0048a0;    
}

.hl:hover {
  color: #2f86ef; 
}

.loadMore {
  cursor: pointer;
  border: 0.25px solid darkgray;
  margin-top: 20px;
  margin-bottom: 40px;
  vertical-align: middle;
  padding: 15px;
  width: 250px;
  text-align: center;
  border-radius: 2px;
}
