body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noScroll {
  overflow: hidden !important;
}

.continent {
  cursor: pointer;
  font-size: 18px; 
  border-radius: 3px; 
  padding: 3px;
  background: rgb(22, 142, 39, 0.9);
  color: white;
  font-weight: 700;
  display: inline-block;
  border: 2px solid white;
  position: relative;  /* Make the span a reference point for the tooltip positioning */
}

.continent::before {
  content: attr(data-name);  /* Use the continent name from the data-name attribute */
  display: none;
  position: absolute;
  bottom: 100%;  /* Position tooltip above the span */
  left: 50%;  /* Center the tooltip */
  transform: translateX(-50%);  /* Shift tooltip to the left by half its width for centering */
  background-color: white;
  color: rgb(22, 142, 39);
  padding: 5px;
  border-radius: 3px;
  font-weight: normal;  /* Reset font-weight for tooltip */
  white-space: nowrap;  /* Prevent tooltip text from wrapping */
  margin-bottom: 5px;  /* Space between the tooltip and the span */
  z-index: 1;  /* Ensure tooltip is on top */
}

/* Display the tooltip on hover */
.continent:hover::before {
  display: block;
}

.country {
  cursor: pointer;
  font-size: 20px; 
  border-radius: 3px; 
  padding: 3px;
  background: rgb(22, 142, 39, 0.9);
  color: white;
  font-weight: 700;
  display: inline-block;
  border: 2px solid white;
  position: relative;  /* Make the span a reference point for the tooltip positioning */
}

/* Tooltip styling */
.country::before {
  content: attr(data-name);  /* Use the country name from the data-name attribute */
  display: none;
  position: absolute;
  bottom: 100%;  /* Position tooltip above the span */
  left: 50%;  /* Center the tooltip */
  transform: translateX(-50%);  /* Shift tooltip to the left by half its width for centering */
  background-color: white;
  color: rgb(22, 142, 39);
  padding: 5px;
  border-radius: 3px;
  font-weight: normal;  /* Reset font-weight for tooltip */
  white-space: nowrap;  /* Prevent tooltip text from wrapping */
  margin-bottom: 5px;  /* Space between the tooltip and the span */
  z-index: 1;  /* Ensure tooltip is on top */
}

/* Display the tooltip on hover */
.country:hover::before {
  display: block;
}

.redDot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -10px;
  z-index: 10;
  border: 2px solid white;
}


