.Mobile {
    position: fixed;
    top: 0px;
    left: 0px;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    touch-action: none;
}

.MobileContents {
    position: fixed;
    top: 0px;
    left: 0px;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    touch-action: none;
    margin-left: 10px;
    margin-right: 10px;
}

.hamburger {
    z-index: 10;
    position: fixed;
    top: 5px;
    left: 5px;
    color: white;
    font-size: 28px;
}

.menu {
    width: 100%;
    height: 100vh;
    overflow: auto;
    transition: transform 0.3s ease-out;
}

.search {
    width: 100%;
    height: 100vh;
    overflow: auto;
    color: black;
    transition: transform 0.3s ease-out;
}

.results {
    width: 100%;
    height: 100vh;
    overflow: auto;
    color: black;
    transition: transform 0.3s ease-out;
}

.record {
    width: 100%;
    height: 100vh;
    overflow: auto;
    color: black;
    transition: transform 0.3s ease-out;
}