@font-face {
  font-family: "robust";
  font-style: normal;
  font-weight: normal;
  src: url("../public/fonts/robust-webfont.woff") format("woff"),url("../public/fonts/robust-webfont.woff2") format("woff2");
  font-display: swap
}

@font-face {
  font-family: "robustCMN";
  font-style: normal;
  font-weight: normal;
  src: url("../public/fonts/RobustCMN.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'National-Book';
  src: url('../public/fonts/National-Book.otf');
  src: local('☺'),url('../public/fonts/National-Book.otf?#iefix') format('otf'),url('../public/fonts/National-Book.otf#National-Book');
  font-weight: 400;
}

@font-face {
  font-family: 'National-Medium';
  src: url('../public/fonts/National-Medium.otf');
  src: local('☺'),url('../public/fonts/National-Medium.otf?#iefix') format('otf'),url('../public/fonts/National-Medium.otf#National-Medium');
  font-weight: 400;
}

@font-face {
  font-family: 'National-Book';
  src: url('../public/fonts/National-Book.otf');
  src: local('☺'),url('../public/fonts/National-Book.otf?#iefix') format('otf'),url('../public/fonts/National-Book.otf#National-Book');
  font-weight: 400;
}

@font-face {
  font-family: 'Newsreader';
  src: url('../public/fonts/Newsreader-VariableFont_opsz,wght.ttf');
  src: local('☺'), url('../public/fonts/Newsreader-VariableFont_opsz,wght.ttf?#iefix') format('truetype'),
       url('../public/fonts/Newsreader-VariableFont_opsz,wght.ttf#Newsreader');
  font-weight: 400;
}

@font-face {
  font-family: 'Newsreader-Italic';
  src: url('../public/fonts/Newsreader-Italic-VariableFont_opsz,wght.ttf');
  src: local('☺'), url('../public/fonts/Newsreader-Italic-VariableFont_opsz,wght.ttf?#iefix') format('truetype'),
       url('../public/fonts/Newsreader-Italic-VariableFont_opsz,wght.ttf#Newsreader-Italic');
  font-weight: 400;
}

* {
  font-family: "National-Book", sans-serif;
}

.debugLink {
    display: none;
    z-index: 1000000;
    position: fixed;
    top: 10px;
    right: 10px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: calc(100vh - 100px); /* Assuming header and footer height is 100px */
  }
  
  .middlePane {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .topMiddlePane {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .bottomMiddlePane {
    background-color: #F2F2F2;
    display: flex;
  }
  
  .leftColumn {
    text-align: left;
    top: 0px;
    width: 160px;
    margin-left: 0px;
    background-color: #B5341D;
    color: #f0f0f0;
  }

  .facetContainer {
    width: 100%;
    cursor: pointer;
    position: relative;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 21px;
  }

  .facetContainer:hover {
    border-radius: 0px;
    background-color: #656B72;
  }

.facetName {
  margin-left: 14px;
  margin-right: 10px;
  width: 65%;
  text-align: left;
  padding: 10px;
  border-radius: 4px;
}

.facetNameSelected {
  border: 1px solid #E7E4DF;
}

.facetActiveDot {
  content: '';
  position: absolute;
  top: 6px;
  right: 14px;
  width: 8px;
  height: 8px;
  background-color: rgb(255,0,0,0.7);
  border: 1px solid #E7E4DF;
  border-radius: 50%;
}

  .facet {
    box-sizing: border-box;
    text-align: left;

    opacity: 0;
    visibility: hidden;
    z-index: -1000;
    
    position: fixed;
    top: 0px;
    left: 160px;
    width: 340px;
    height: 100vh;
    transition: opacity 0.3s 0.3s, visibility 0.3s 0.3s, z-index 0.3s 0.6s;
    border-radius: 0px;
    background-color: #656B72;
    padding: 20px;
    fill: #f0f0f0;
    overflow: auto;
}

.facetContainer:hover .facet {
    opacity: 1;
    z-index: 1001;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
}

.facetContainer:hover .facetNameSelected {
  border: none;
}
 
.facetContainer:hover .facetActiveDot {
  display: none;
}

  .rightColumn {
    padding-left: 30px;
    padding-top: 20px;
    flex: 1;
  }

  .filter {
    border: 1px solid #d0d0d0;
    border-radius: 2px;
    margin-bottom: 15px;
    padding: 10px;
  }

  .displayControl {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
  }

.displayControlSeperator {
  margin-left: 12px;
  margin-right: 12px;
  border-left: 1px solid gainsboro;
}

.navigatorBottom {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 650px;
}

.simpleSearchBox {
  flex: 1;                   /* make the search box take up all available space */
  max-width: 800px;
}

.searchContainer {
  display: flex;             /* keep the container as a flex container */
  align-items: center;       /* vertically center the items */
  justify-content: center;   /* horizontally center the items */
  gap: 10px;                 /* keeps the existing space between items */
}

.advancedSearchButton {
  margin-top: 8px;
}

.map {
  margin: 0px auto;
  margin-top: 30px;
  text-align: center;
  width: 1000px;
  height: 400px;
}

.homepageBlocks {
  margin: 20px auto 30px auto; /* The left and right margins are set to auto to center the blocks */
  text-align: center;
  display: flex;
  justify-content: center; /* Horizontally center the flex items */
  align-items: flex-start; /* Align the flex items at the top */
  flex-wrap: wrap; /* Allow the blocks to wrap to the next line if there's not enough space */
  padding-top: 20px;
}

.filterHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #b8b8b8
}

.filters {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 0.2px solid #585858;
  border-bottom: 0.2px solid #585858;
}

.facets {
  padding-top: 20px;
}

.scrollToTop {
  position: fixed;
  bottom: 30px;
  right: 0px;
  background-color: rgb(200,200,200,0.5);
  color: #777777;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 2px;
  z-index: 1000;  /* Ensure it appears above other content */
  transition: opacity 1s;
}

.homePageButton {
  position: absolute;
  margin-top: 8px;
  margin-left: 170px;
}

.intro {
  text-align: center;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 600px;
}

.introText {
  font-size: 18px;
  line-height: 27px;
  color: #484848;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introTextText {
  text-align: left;
}

.monumentImage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  object-fit: cover;
}

.introAdditionalText {
  margin-top: 20px;
  font-size: 18px;
  line-height: 27px;
  color: #484848;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  pointer-events: none;
}

.isExpanded {
  opacity: 1;
  transform: translateY(0);
}

.introMoreButton {
  display: block;
  margin-top: 15px;
  cursor: pointer;
}

.introHyperlink {
  cursor: pointer;
  display: block;
  padding-top: 16px;
  font-size: 22px;
  font-style: italic;
  text-decoration: none;
  color: #555555;
  text-decoration: underline;
}

.introHyperlink:hover {
  text-decoration: underline;
}

.hyperlinkBackToCollection {
  text-align: center;
  cursor: pointer;
}

.introText {
  overflow: hidden;
}
