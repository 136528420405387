.genericCheckboxInput {
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
}

.genericCheckboxLabel {
  position: relative;
  line-height: 20px;
  display: flex;
  align-items: flex-start; /* Aligns items to the top */
}

.icon {
  margin-top: 3px;
  background-color: white;
  border-radius: 2px;
  margin-right: 8px;
  padding: 2px;
  width: 9px;
  height: 9px;
  transition: background 0.3s;
  border: 1px solid black;
  fill: currentColor;
  flex-shrink: 0;  /* Prevents the checkbox from shrinking */
}

.labelText {
  flex: 1;  /* Allows the label text to take up the remaining space */
  display: block; /* Ensures it behaves like a block level element */
}

.genericCheckboxInput:checked + .genericCheckboxLabel .icon {
  background-color: rgba(255, 255, 255, 1);
  fill: black;
}
