.genericIconButton {
    display: inline-block;
    padding: 6px;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: transparent;
    border-radius: 8%;
    cursor: pointer;
  }

  .hoverEnabled:hover {
    background-color: var(--hover-bg-color);
    fill: #0000ff;
    transform: translate3d(0,0,0);
  }
  
.genericIconButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
  
.genericIconButtonDisabled {
    pointer-events: none;  /* Prevents the div from being clickable */
    /* Any other styles you want for the disabled state */
}

.genericIconButtonActive {
    border: 1px solid var(--active-border-color);
}
  
.iconInsideButton {
  width: 100%;
  height: 100%;
}
