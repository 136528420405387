.RecordList {
    page-break-inside: avoid !important;
    /* Reset the <a> tag to avoid it appearing like a hyperlink */
    text-decoration: none;
    color: inherit;
}

.ccListviewRecord:hover {
    /* If you still want the underline on hover for the whole block, keep this */
    text-decoration: none; 
}

.ccListviewRecord .panel-title {
    /* cursor: pointer; */ /* Commented out unless you want a clickable appearance */
    color: #333; /* or whatever color you want that isn't hyperlink blue */
}

.row {
    display: block;
    margin-bottom: 8px;
}

.taglabel {
    font: normal 1em "National-Book", sans-serif;
    font-weight: 700;
}

.listViewPanel {
    border: 1px solid darkgray;
    border-radius: 2px;
    padding: 0px;
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 20px;
}

a {
    text-decoration: none;
}

.hl {
    text-decoration: none;
    color: #0048a0;    
}

.hl:hover {
    color: #2f86ef; 
}

.ccListviewRecordImage img {
    vertical-align: top;
}

.thesNode {
    cursor: pointer;
    display: inline-block;
}

.thesNodeSeperator {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
}

.thesNode:hover {
    text-decoration: underline;
}

.authName {
    cursor: pointer;
}

.authName:hover {
    text-decoration: underline;
}

.hyperlink {
    cursor: pointer;
  }
  
  .hyperlink:hover {
      text-decoration: underline;
  }


  .ccListviewRecordTable {
    border-spacing: 0px;
    width: 100%;
    display: flex;  /* Added flexbox layout */
}

.ccListviewRecordImage {
    width: 220px;
    margin-right: 25px;
    /* display: inline-block; */ /* Removed inline-block */
}

.ccListviewRecordText {
    /* display: inline-block; */ /* Removed inline-block */
    vertical-align: top;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    flex: 1;  /* This ensures the text container takes up the remaining width */
}
