.Record {
    width: 100%;
    height: 100%;
    overflow: auto;
    /* Clearfix for the floated child */
}

.ccListviewRecordImage {
    float: left;
    /* Float the image to the left */
    margin-right: 25px;
    cursor: pointer;
    width: 450px;
}

.ccListviewRecordText {
    vertical-align: top;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 20px;
    margin-left: 475px;
    /* Width of .ccListviewRecordImage + its right margin */
    height: 85%;
    /* Take up the full height of the parent */
    overflow-y: auto;
    /* Show vertical scrollbar if content overflows */
}


.ccListviewRecord .panel-title {
    cursor: pointer;
    color: #337AB7;
}

.ccListviewRecord .panel-title:hover {
    text-decoration: underline;
}

.ccListviewRecordTable {
    width: 100%;
}

.taglabel {
    font: normal 1em "National-Book", sans-serif;
    font-weight: 700;
}

.tagValue {
    line-height: 25px;
}

.listViewPanel {
    border: 1px solid darkgray;
    border-radius: 2px;
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 20px;
    width: 100%;
    height: 100%;
    display: flex;
}

.ZoomedImage {
    width: 450px;
    height: 400px;
    border-right: 0.25px solid #e0e0e0;

}

a {
    text-decoration: none;
}

.hl {
    text-decoration: none;
    color: #0048a0;
}

.hl:hover {
    color: #2f86ef;
}

.filmStripContainer {
    width: 440px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 0px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    border-right: 0.25px solid #e0e0e0;
}

.filmStrip {
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
    flex-grow: 1;
    overflow-x: hidden;
    display: flex;
    gap: 10px;
    padding: 10px 0;
    margin-top: 10px;
}

.filmStripImage {
    height: 50px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.buttonContainer {
    flex-shrink: 0;
}

.thesNode {
    cursor: pointer;
    display: inline-block;
}

.thesNodeSeperator {
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
}

.thesNode:hover {
    text-decoration: underline;
}

.row {
    display: block;
    margin-bottom: 8px;
}

.boldrow {
    display: block;
    margin-bottom: 8px;
    font-weight: 550;
}

.authName {
    cursor: pointer;
}

.authName:hover {
    text-decoration: underline;
}

.hyperlink {
    cursor: pointer;
    text-decoration: underline;
}

.hyperlink:hover {
    text-decoration: underline;
}

.comments {
    position: absolute;
    display: inline-block;
    margin-top: 7px;
    margin-left: 20px;
}

.comments:hover {
    text-decoration: underline;
}

.recordInput,
.recordTextarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 1rem;
    width: 90%;
}

.formButtons {
    margin-top: 20px;
    width: 100%;
    text-align: right;
}

.tagValue {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}

.tagValueHyperlink {
    cursor: pointer;
    text-decoration: underline;
    color: black;
}

.collapsed {
    max-height: 4.5em;
    /* Adjust as needed for your font-size/line-height */
}

.expanded {
    max-height: none;
}

.toggleButtonContainer {
    position: relative;
    padding-top: 10px;
    width: 100%;
    height: 10px;
}

.toggleButton {
    position: absolute;
    right: 0px;
    width: 22px;
    height: 22px;
    background: transparent;
    border: 1px solid lightgray;
    padding: 0;
    /* Adjust or remove padding */
    border-radius: 50%;
    display: flex;
    /* Enables Flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    cursor: pointer;
    /* Changes cursor to pointer on hover */
    font-size: 18px;
    /* Adjust the font size as needed */
    line-height: 1;
    /* Ensures the text is aligned to its own height */
}


.toggleButtonAssoc {
    margin-left: 10px;
    width: 22px;
    height: 22px;
    background: transparent;
    border: 1px solid lightgray;
    padding: 0;
    /* Adjust or remove padding */
    border-radius: 50%;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    cursor: pointer;
    /* Changes cursor to pointer on hover */
    font-size: 18px;
    /* Adjust the font size as needed */
    line-height: 1;
    /* Ensures the text is aligned to its own height */
}

.objAssoc {
    margin-top: 20px;
}

.title {
    font-size: 1.4em;
    font-weight: 700;
    margin-bottom: 10px;
}

.copyright {
    background-color: red;
    border: 1px solid blue;
}