/* Container Styles */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.masonryContainer {
  display: flex;
  flex-direction: column;
}

.columnsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* This replaces the padding in the column and provides spacing between columns */
}

.column {
  flex: 1; /* This will make all columns equally share the available space */
  min-width: 180px; /* Adjust based on your needs */
}

.listItem {
  flex: 0 0 100%;
}

.loaderContainer {
  position: relative;
  width: 100%;
  text-align: center;
}

.loader {
  text-align: center;
}

.record {
  display: inline;
}

/* List View Record Styles */
.ccListviewRecord {
  page-break-inside: avoid !important;
  /* Reset the <a> tag to avoid it appearing like a hyperlink */
  text-decoration: none;
  color: inherit;
}

.ccListviewRecord:hover {
  /* If you still want the underline on hover for the whole block, keep this */
  text-decoration: none; 
}

.ccListviewRecord .panel-title {
  /* cursor: pointer; */ /* Commented out unless you want a clickable appearance */
  color: #333; /* or whatever color you want that isn't hyperlink blue */
}

.ccListviewRecordTable {
  border-spacing: 0px;
  width: 100%;
}

.listViewPanel {
  border: 1px solid darkgray;
  border-radius: 2px;
  padding: 0px;
  background-color: #ffffff;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
}

.ccListviewRecordImage {
  display: inline-block;
  width: 180px;
  margin-right: 25px;
}

.ccListviewRecordImage img {
  vertical-align: top;
}

.ccListviewRecordText {
  display: inline-block;
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

/* Other Styles */

.taglabel {
  font: normal 1em "National-Book", sans-serif;
  font-weight: 700;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.hl {
  text-decoration: none;
  color: #0048a0;    
}

.hl:hover {
  color: #2f86ef; 
}

.loadMore {
  cursor: pointer;
  border: 0.25px solid darkgray;
  margin-top: 20px;
  margin-bottom: 40px;
  vertical-align: middle;
  padding: 15px;
  width: 250px;
  text-align: center;
  border-radius: 2px;
}
