.RecordIcon {
    position: relative; /* To position the overlay correctly */
    box-sizing: border-box;
    width: 240px;
    margin-right: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    vertical-align: top;
    margin-bottom: 20px;
    text-decoration: none;
    color: inherit;
    background-color: white;
    min-height: 160px;
}

.iconViewRecord {
    box-sizing: border-box;
    height: 160px;
}

.iconViewRecordText {
    box-sizing: border-box;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    min-height: 20px;
}

.hoverOverlay {
    box-sizing: border-box;
    position: absolute;
    padding: 15px;
    left: -1px;
    top: 0px;
    width: 242px;
    height: 160px;
    background-color: rgba(255, 255, 255, 0.7); /* Transparent white */
    color: black;
    overflow: auto;
}

.title {
    font-size: 1.1em; /* Adjust title size as needed */
    font-weight: bold;
    margin-bottom: 5px;
}

.staticText {
    padding-top: 15px;
    font-weight: bold;
}

.country {
    font-size: 1em; /* Adjust country size as needed */
}

