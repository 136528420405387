.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Ensure the container takes full height */
}

.header {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  line-height: 1;
  color: #333;
}

.closeButton:hover {
  color: #999;
}

.footer {
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;
  padding-bottom: 20px; /* Add some padding at the bottom */
}

.main {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 40px);
  overflow-y: auto; /* Add vertical scrollbar */
  flex-grow: 1; /* Allow main to grow and take available space */
}

.searchstyle {
  padding: 20px;
}

.row {
  display: flex; 
  align-items: center;
}

.fieldLabel {
  display: inline-block;
  padding-top: 7px;
  padding-right: 8px;
  padding-left: 8px;
  flex-basis: 200px;
  flex-shrink: 0;
}

.textBoxAndButton {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: calc(100% - 200px);
}

.textInput {
  flex-grow: 1;
}

.inputWithResults {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.results {
  position: absolute;
  top: 100%;
  margin-top: 5px;
  left: 5px;
  border: 1px solid #000;
  background-color: white;
  z-index: 102;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #000;
  width: 60vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 101;
}

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.popupcontent {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it takes full height */
  overflow: hidden; /* Hide overflow */
}