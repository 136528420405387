/* General Styles */
header.global {
  _position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  color: black;
}


header.global div.top {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute space between elements */
  height: 100%;
  padding: 0px;
}

.monumentImage {
  height: 100px;
  /* Ensure the image fills the height of the header */
  width: auto;
  /* Maintain aspect ratio */
}

.headerImage {
  margin-left: 20px;
  width: 300px;
}

.monumentInfo {
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px; /* Ensure equal spacing on the right */
  display: flex;
  justify-content: center;  /* Center-aligns the content horizontally */
  align-items: center;      /* Center-aligns the content vertically */
  font-family: 'Newsreader', serif;
  font-weight: 600;
  font-display: swap;
  font-size: 36px;
  line-height: 1;
  color: #202124;
  text-align: center;      /* Ensures the text within the div is centered */
  flex-grow: 1;
}

.languageSelectorContainer {
  padding-right: 15px;
}

.languageSelector {
  padding: 5px;
  border: 0;
  border-radius: 2px;
}

.languageSelector:focus {
  outline: none;
}

.header_logo {
  cursor: pointer;
}

.header_logo_text {
  font-family: "robustCMN";
  text-transform: uppercase;
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  width: 100%;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.header_logo_svg {
  margin-left: 15px;
}