/* Global Styles for the Component */
.HierarchicalFacetValueBrowser {
  color: black;
}

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; /* Put it behind the modal */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #000;
  width: 60vw;
  height: 90vh;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Header Styles */
.header {
  display: flex;
  align-items: center;
  gap: 4px;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 0px;
}

.inputContainer {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  display: flex;
  align-items: center;
}

.mask {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 2px 8px;
  font-size: 14px;
  border: 0.25px solid #168e27;
  border-radius: 2px;
  height: 20px;
  outline: none;
  overflow: hidden;
}

.mask::placeholder {
  color: #a0a0a0;
  font-size: 14px;
}

.sortButtons {
  flex-shrink: 0;
}

.facetContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 8px;
}

.facetName {
  display: flex;
  justify-content: space-between;
  font-weight: 100;
  margin-bottom: 12px;
  padding: 12px 12px;
  background-color: #f0f0f0;
}

.selectedItems {
  padding-left: 8px;
  padding-bottom: 3px;
  margin-bottom: 8px;
}

.clearAllContainer {
  display: block;
  margin-top: 4px;
  margin-bottom: 10px;
}

.clearAllIconContainer {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.clearAll {
  display: inline-block;
  position: absolute;
  margin-top: 6px;
  width: 10px;
  height: 10px;
  border: 0.9px solid black;
  background-color: white;
  border-radius: 1px;
}

.clearAll:nth-child(2) {
  top: 3px;
  left: 3px;
}

.clearAll:nth-child(3) {
  top: 6px;
  left: 6px;
}

.clearAllText {
  padding-left: 3px;
  display: inline-block;
  color: rgb(255,255,255,0.8);
}

.content {
  position: relative;
  flex-grow: 1;
  padding: 0px 6px 0px 8px;
  overflow-y: auto;
}

.footer {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: right;
}
