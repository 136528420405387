.Menu {
  display: block;
  height: 100vh;
  overflow: hidden;
}

.menucontainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.header {
  margin: 10px 5px;
  height: 50px;
  background-size: contain;
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center;
  position: relative;
}

.header_logo {
  cursor: pointer;
  display: flex; /* Added to align logo elements horizontally */
  align-items: center; /* Vertically center elements */
}

.header_logo_text {
  font-family: "robust";
  text-transform: uppercase;
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  width: 100%;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.header_logo_svg {
  margin-left: 15px;
}

.topRightButton {
  display: inline-block;
  position: absolute;
  right: 10px; /* Position it to the right side */
  margin-left: 15px;
}

.menubody {
  padding-top: 0px;
  background-color: rgb(216, 216, 216);
  flex-grow: 1;
  width: 100%;
  text-align: left;
  overflow: auto;
  color: white;
  cursor: pointer;
}

/* Styling the scrollbar track */
.menubody::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

.menubody::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  /* color of the tracking area */
}

/* Styling the scrollbar handle or thumb */
.menubody::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  /* color of the scroll thumb */
  border-radius: 4px;
  /* roundness of the scroll thumb */
  border: 2px solid transparent;
  /* creates padding around scroll thumb */
}

/* Styling the scrollbar thumb when it's being hovered or clicked */
.menubody::-webkit-scrollbar-thumb:hover,
.menubody::-webkit-scrollbar-thumb:active {
  background-color: rgba(255, 255, 255, 0.6);
}

.bodyitem {
  margin: 0px 30px 0px 20px;
  padding: 10px 22px;
  font-size: 20px;
  color: white;
  line-height: 45px;
  border-bottom: 1px solid #359944;
}

.bodyitem a {
  color: white;
}

.hamburger {
  position: fixed;
  left: 5px;
  top: 5px;
  z-index: 10;
}

.accessibility {
  display: inline-block;
}

.socials {
  display: inline-block;
}

.iconContainer {
  margin-bottom: 10px;
}

.wheelchair,
.visual,
.hearing,
.culture,
.lsf {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  background-size: cover;
}

.facebook,
.twitter,
.insta,
.linktree {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  background-size: cover;
}

.wheelchair {
  background-image: url('wheelchair.png');
}

.visual {
  background-image: url('visual.png');
}

.hearing {
  background-image: url('hearing.png');
}

.culture {
  background-image: url('culture.png');
}

.facebook {
  background-image: url('facebook.png');
}

.twitter {
  background-image: url('twitter.png');
}

.insta {
  background-image: url('insta.png');
}

.linktree {
  background-image: url('linktree.png');
}

.lsf {
  background-image: url('lsf.png');
}

.topLeftButton {
  display: inline-block;
  margin-right: 15px;
}

.footer {
  background-color: #000;
  color: #fff;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.title_and_subscribe {

}

.title {
  font-size: 28px;
  font-weight: 700;
}

.subscribe {
  border-bottom: 1px solid white;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.socialIcons {
  display: flex;
  gap: 0.1rem;
}

.socialIcons a {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  border: none;
  border-top: 1px solid rgb(104, 114, 125);
  margin: 1rem 0;
}

.linksSectionWrapper {
  display: flex;
  justify-content: left;
}

.linksSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 8rem;
  max-width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
  max-width: 350px;
}

.column a, .column h3 {
  color: #fff;
  text-decoration: none;
  font-size: 1.0rem;
  font-weight: 700;
}

.column h3 {
  color: rgb(172, 178, 185);
  margin: 0px;
  margin-bottom: 0.5rem;
  font-size: 1.0rem;
  font-weight: 100;
}

.logoSection {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.logo {
  height: 50px;
}

.bottomSection {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.bottomSection a, .actionButton {
  color: #fff;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
}

.separator {
  margin: 0 0.5rem;
}

.rightActions {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .topSection, .linksSection, .bottomSection {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .socialIcons, .rightActions {
    margin-top: 1rem;
  }

  .linksSection {
    gap: 1rem; /* Adjust gap for mobile */
  }
}

.hyperlinkIconParent {
  position: relative;
  display: inline-block;
}

.hyperlinkIcon {
  position: absolute;
  left: 0px;
  top: -10px;
  height: 12px;
  width: 12px;
  fill: white;
  transform: rotate(45deg);
}
