.nodeContainer {
    display: flex;
    align-items: flex-start;
    gap: 10px; /* Gap between items */
}
 
  .toggleIcon {
    cursor: pointer;
    width: 5px;
  }
  
  .checkboxLabel {
    margin-left: 5px;
  }

.searchContainer {
    display: inline-block;
    text-align: right;
    right: 12px;
    width: 100%;
    margin-bottom: 16px;
  }

  .expandButton {
    display: inline-block;
    width: 12px;
    height: 12px;
  }