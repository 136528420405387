.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #000;
    width: 95vw;
    height: 95vh;
    overflow: hidden; /* to hide the scrollbars */
    display: flex;
    flex-direction: column; /* Add this line */
    justify-content: space-between; /* Add this line */
    z-index: 101;
  }  

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; /* Put it behind the modal */
}

.header {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.main {
  display: flex;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  flex-grow: 1;
}
  
.footer {
    background-color: #f0f0f0;
    text-align: right;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px;
  }

  .leftColumn {
    border-right: 1px solid #d8d8d8;
    padding-right: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column; /* Add this line */
  }

  .treeView {
    flex: 1;
    margin-right: 10px;
    padding-right: 20px;
  }

  .leftColumnFooter {
    flex-grow: 0;
    flex-shrink: 0;
}

.editor  {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editorContent {
  flex-grow: 1;
  overflow: hidden;
  height: 250px;
}

.scrollableAnnotationContainer {
  height: 100%;
  overflow-y: auto;
}

  .expandButtonContainer {
    display: inline-block;
    width: 12px;
  }

  .expandButton {
    cursor: pointer;
    display: inline-block;
    width: 12px;
    height: 12px;
  }

  .nodeText {
    display: inline-block;
    margin-left: 4px;
    cursor: pointer;
  }

  .nodeText:hover {
    color: #1450ab;
    text-decoration: underline;
  }

  .children {
    display: block;
  }

  .editorHeader { 
    padding: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    flex-grow: 0;
    flex-shrink: 0;
}

.editorEditor {
  flex-grow: 1;
}

.editorFooter {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 10px;
}

.contextMenu {
  position: fixed;
  border: 2px solid #d8d8d8;
  border-radius: 2px;
  background-color: #f8f8f8;
  min-width: 150px;
  z-index: 10;  
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.contextMenuItem {
  display: block;
  cursor: pointer;
  padding: 12px;
}

.contextMenuItem:hover {
  color: #f8f8f8;
  background-color: rgb(85, 120, 224);
}

.selectedNode {
  font-weight: 550;
}

.annotationContainer {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px; /* Ensure there's space for the footer */
}
