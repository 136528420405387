.CurPage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.currentPage {
    cursor: pointer;
}

.currentPage:hover {
    text-decoration: underline;
}