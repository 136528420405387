.annotationControl {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
}

.imageName {
    margin-right: 10px;
    padding: 5px;
}

.uploadButton,
.toolButton,
.deleteButton {
    margin-right: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.toolButton.active {
    background-color: #3b82f6;
    color: white;
}

.annotationText {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.annotationTextarea {
    flex-grow: 1;
    margin-right: 10px;
    padding: 5px;
}

.imageContainer {
    flex-grow: 1;
    overflow: auto;
    position: relative;
}

.imageWrapper {
    position: relative;
}

.image {
    display: block;
    max-width: 100%;
    height: auto;
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: crosshair;
}