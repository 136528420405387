
.popup {
    position: fixed;
    top: 100px;
    left: 100px;
    width: 300px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    z-index: 1000;
  }

  .resizable_popup {
    resize: both; /* Allow resizing both horizontally and vertically */
    overflow: hidden; /* Hide overflow when resizing */
    width: 500px; /* You can set a width and height to enable resizing */
    height: 300px;
  }
  
  .scrollable_content {
    max-height: 100%;
    overflow: auto; /* Enable both vertical and horizontal scrolling if content overflows */
  }
  
  .popupHeader {
    background-color: #f0f0f0;
    padding: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    margin-bottom: 10px;
  }
  
  .popupContent {
    padding: 10px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    line-height: 1;
    color: #333;
  }
  
  .closeButton:hover {
    color: #999;
  }
  