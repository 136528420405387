.footer {
  background-color: #000;
  color: #fff;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.title_and_subscribe {

}

.title {
  font-size: 28px;
  font-weight: 700;
}

.subscribe {
  border-bottom: 1px solid white;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.socialIcons {
  display: flex;
  gap: 0.1rem;
}

.socialIcons a {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  border: none;
  border-top: 1px solid rgb(104, 114, 125);
  margin: 1rem 0;
}

.linksSectionWrapper {
  display: flex;
  justify-content: left;
}

.linksSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 8rem;
  max-width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
  max-width: 350px;
}

.column a, .column h3 {
  color: #fff;
  text-decoration: none;
  font-size: 1.0rem;
  font-weight: 700;
}

.column h3 {
  color: rgb(172, 178, 185);
  margin: 0px;
  margin-bottom: 0.5rem;
  font-size: 1.0rem;
  font-weight: 100;
}

.logoSection {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.logo {
  height: 50px;
}

.bottomSection {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.bottomSection a, .actionButton {
  color: #fff;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
}

.separator {
  margin: 0 0.5rem;
}

.rightActions {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .topSection, .linksSection, .bottomSection {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .socialIcons, .rightActions {
    margin-top: 1rem;
  }

  .linksSection {
    gap: 1rem; /* Adjust gap for mobile */
  }
}

.hyperlinkIconParent {
  position: relative;
  display: inline-block;
}

.hyperlinkIcon {
  position: absolute;
  left: 0px;
  top: -10px;
  height: 12px;
  width: 12px;
  fill: white;
  transform: rotate(45deg);
}


