.MobileHomepage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  /* Prevent scrolling */
}

.header {
  margin: 10px 5px;
  height: 50px;
  background-size: contain;
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center;
  position: relative;
}

.header_logo {
  cursor: pointer;
  display: flex; /* Added to align logo elements horizontally */
  align-items: center; /* Vertically center elements */
}

.header_logo_text {
  font-family: "robust";
  text-transform: uppercase;
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  width: 90%;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.header_logo_svg {
  margin-left: 15px;
}

.languageSelectorContainer {
  display: inline-block;
  position: absolute;
  right: 10px; /* Position it to the right side */
  margin-left: 15px;
}

.languageSelector {
  padding: 5px;
  border: 0;
  border-radius: 2px;
  font-size: 1.2rem;
}

.languageSelector:focus {
  outline: none;
}

.header_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_logo_svg img {
  max-width: 90%;
  height: auto;
}

.contents {
  flex: 1;
  /* Make contents take the remaining space */
  overflow-y: auto;
  /* Allow internal scrolling if needed */
}

.intro {
  text-align: center;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 600px;
}

.introText {
  font-size: 1.2rem;
  line-height: 27px;
  color: #484848;
}

.introHyperlink {
  cursor: pointer;
  display: block;
  padding-top: 16px;
  font-size: 22px;
  font-style: italic;
  text-decoration: none;
  color: #555555
}

.introHyperlink:hover {
  text-decoration: underline;
}

.intro button {
  font-size: 1.4rem;
  /* Bigger font size for mobile */
  padding: 10px;
  border-radius: 4px;
  /* Rounded corners */
  border: 1px solid #ccc;
  /* A subtle border */
  margin: 5px 0;
  /* Some margin to separate them */
  width: 90%;
  /* Full width */
}

.intro button {
  background-color: rgb(181, 52, 29);
  color: #fff;
  /* White text on the button */
  border: none;
  /* Remove the border for the button */
  cursor: pointer;
}

.homepageBlocks {
  margin: 60px auto 80px auto;
  /* The left and right margins are set to auto to center the blocks */
  text-align: center;
  display: flex;
  justify-content: center;
  /* Horizontally center the flex items */
  align-items: flex-start;
  /* Align the flex items at the top */
  flex-wrap: wrap;
  /* Allow the blocks to wrap to the next line if there's not enough space */
}

@media (orientation: portrait) and (max-width: 1024px) {
  .MobileHomepage {
    height: 100vh;
  }

  .header {
    height: auto;
  }

  .contents {
    height: calc(100vh - 60px);
    /* Adjust height based on header size */
  }
}

.infoButton {
  position: absolute;
  bottom: 80px;
  left: 10px;
  padding: 8px;
  width: 35px;
  height: 35px;
  background-color: rgba(181, 52, 29, 0.6);
  border-radius: 50%;
  border: 2px solid white;
  fill: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middleEmptySpace {
  display: block;
  height: 60px;
}

.bottomEmptySpace {
  display: block;
  height: 600px;
}
