/* EditorContent.module.css */

h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  h2 {
    font-size: 1.75em;
    margin-bottom: 0.5em;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 0.5em;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  