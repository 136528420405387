 
  .genericSelect {
    padding: 8px 40px 8px 10px;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    background-color: white;
    font-size: 14px;
    -webkit-appearance: none; /* For Safari and older versions of Chrome */
    appearance: none; /* Standard property for other browsers */
    background-image: url('../fontawesome/svgs/light/angle-down.svg');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 10px;
    outline: none;
    transition: border-color 0.3s;
}

.genericSelect:focus {
    border-color: #d0d0d0;
}

.GenericButton {
  cursor: pointer;
  margin: 0 3px;
  padding: 8px 10px 8px 10px;
  border: none;
  border-radius: 2px;
  background-color: #f0f0f0;
  color: #808080;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.GenericButton:hover {
  background-color: #e0e0e0;
  color: #606060;
}

.GenericButton:disabled {
  background-color: transparent;
  cursor: not-allowed;
  color: rgb(64,64,64,0.25);
}


