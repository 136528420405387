.Paginator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginateButton {
  width: 20px;
  height: 18px
}
