.RecordPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #000;
  width: 90vw;
  height: 90vh;
  overflow: hidden;
  /* to hide the scrollbars */
  _display: flex;
  _flex-direction: column;
  z-index: 101;
}

.popupheader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popupcontent {
  height: 100%;
  _display: flex;
  _flex-grow: 1;
}

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(6, 3, 3, 0.5);
  z-index: 100;
  /* Put it behind the modal */
}

.Navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}

.Paginator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Paginator select {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

.paginateButton {
  width: 20px;
  height: 18px
}

.CurPage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-right: 10px;
}

.currentPage {
  cursor: pointer;
}

.currentPage:hover {
  text-decoration: underline;
}

.ResultSetSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.closeButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
}

.socials {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  top: 10px;
}

.socialButtonWrapper {
  display: inline-block;
  margin-right: 10px;
}

.generatePdfButton {
  display: inline-block;
  margin-right: 15px;
}

.closeButton {
  display: inline-block;
  position: relative;
  top: 4px;
}