.ZoomedImage {
    width: 100%;
    height: 100%;
}

.ZoomedImage :focus { outline: none; }

.viewerBody {
    width: '100%';
    height: calc(100% - 55px);
    border: none;
} 

.viewerToolbar {
  text-align: center;
    width: '100%';
    height: 15px;
    margin-top: 10px;
    border: none;
}

.toolbarItem {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 32px;
    border: 1px solid #ddd;
  }
  
  .toolbarItem:hover {
    background-color: #e0e0e0;
  }

  .filterPanel {
    position: fixed;
    left: 0px;
    top: 0px;
  }
  
  .popup {
    position: fixed;
    top: 100px;
    left: 100px;
    width: 300px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    z-index: 1000;
  }
  
  .popupHeader {
    background-color: #f0f0f0;
    padding: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    margin-bottom: 10px;
  }
  
  .popupContent {
    padding: 10px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    line-height: 1;
    color: #333;
  }
  
  .closeButton:hover {
    color: #999;
  }

  .filterContainer {
    margin-bottom: 15px;
  }

  .resetFilterContainer {
    margin-top: 30px;
    margin-bottom: 10px;
  }  
  
  .ZoomedImage button {
    margin: 0 3px;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    background-color: #90969c;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ZoomedImage button:hover {
    background-color: #0056b3;
  }
  
  .ZoomedImage button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .redDot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    top: -4px;
    right: -4px;
  }

  