.facetContainer {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    overflow: visible;
  }
  
  .browseValueContainer {
    display: flex;
    flex-direction: column;
   /*  height: 100%;  /* Make sure it fills up the entire height of its parent */
   overflow: visible;
  }

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Align items vertically in the center */
  background: white;
  margin-bottom: 10px;
}

.maskAndButton {
  flex: 1; /* Allow it to grow and take up available space */
  display: flex; /* Make it flex to align its children horizontally */
  align-items: center; /* Align its children vertically in the center */
  gap: 5px; /* Creates space between its children */
}

.sortButtons {
  display: flex;
  gap: 5px;
  flex-shrink: 0; /* Prevent this container from shrinking */
}

.content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;  /* This will allow it to grow and fill the remaining space */
}

  .facetName {
    font-weight: 550;
  }

  .maskLabel {
    display: inline-block;
  }

  .mask {
    display: inline-block;
    margin-left: 10px;
    width: 200px;
    height: 22px;
    font-size: 16px;
  }

  .clearMaskButton
  {
    display: inline-block;
  }

   .selectedItems {
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
    margin-bottom: 5px;
   }

   .facetValue, .facetValue * {
    font-size: 1.4rem;
    cursor: pointer;
  }
  
.main {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; /* Add this line */
  overflow: hidden; /* Add this line */
}
