/* Existing CSS */
.MobileRecord {
  height: 100vh;
  overflow: auto;
}

.popupheader {
  position: absolute;
  padding-top: 5px;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  z-index: 1000000;
  font-size: 1.2rem;
}

.popupcontent {
  margin-top: 70px;
}

.popupoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(6, 3, 3, 0.5);
  z-index: 100;
}

.Navigator {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.Paginator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Paginator select {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

.paginateButton {
  width: 20px;
  height: 18px;
}

.CurPage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-right: 10px;
}

.currentPage {
  cursor: pointer;
}

.currentPage:hover {
  text-decoration: underline;
}

.ResultSetSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.shareButtonContainer {
  right: 5px;
}

.socialsWideScreen {
  position: absolute;
  right: 50px;
  top: 20px;
}

.socialsNarrowScreen {
  display: none;
}

.socialButtonWrapper {
  display: inline-block;
  margin-right: 10px;
}

.generatePdfButton {
  display: inline-block;
  margin-right: 65px;
  top: 0px;
}

.pdfButtonParent {
  position: relative;
  top: 10px;
  left: -15px;
}

.pdfButtonWideScreen {
  width: 30px;
  top: 0px;
}

.pdfButtonNarrowScreen {
  width: 50px;
  top: 0px;
}

.infoButton {
  position: absolute;
  bottom: 80px;
  left: 10px;
  padding: 8px;
  width: 35px;
  height: 35px;
  background-color: rgba(181, 52, 29, 0.6);
  border-radius: 50%;
  border: 2px solid white;
  fill: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollToTop {
  position: absolute;
  bottom: 80px;
  right: 10px;
  background-color: rgba(216, 216, 216, 0.5);
  color: #856872;
  padding: 4px 8px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;
  z-index: 1000;
  transition: opacity 1s;
  display: none;
}

.scrollToTop.show {
  display: block;
}

@media (max-width: 800px) {
  .Navigator {
    flex-direction: column;
    align-items: flex-start;
  }

  .socials,
  .generatePdfButton {
    margin-top: 10px;
  }

  .popupcontent {
    margin-top: 140px;
  }
}

.shareButton {
  display: none;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
}

@media (max-width: 500px) {

  .socialsNarrowScreen {
    position: relative;
    display: inline-block;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .socialsNarrowScreenContent {
    margin-top: 35px;
  }

  .socialsWideScreen {
    display: none;
  }

  .shareButton {
    display: block;
  }

  .socialButtonWrapper {
    display: block;
    margin-top: 15px;
    text-align: center;
  }

  .Navigator {
    flex-direction: column;
    align-items: flex-start;
  }

  .shareButtonContainer {
    position: absolute;
    right: 55px;
    top: 20px;
  }

  .closeSharePopupButton {
    position: absolute;
    right: 20px;
    top: 0px;
    cursor: pointer;
  }

  .sharePopup {
    display: none;
    position: fixed;
    left: 10%;
    top: 15%;
    bottom: 15%;
    right: 10%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(216, 216, 216, 0.9);
    z-index: 10000000;
  }


  .sharePopup.visible {
    display: block;
  }
}