.RecordIconScroll {
    box-sizing: border-box;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}

.iconViewRecord {
    box-sizing: border-box;
    position: relative;
    width: 180px;
    min-height: 160px;
    margin-bottom: 25px;
    background-color: transparent;
    /* opacity: 1; set initial opacity to 0 */
    /* transition: opacity 0.5s ease-in-out; /* set transition effect for opacity */
  }
  
  /*
  .iconViewRecord.fadeIn {
    opacity: 1; 
  }
  */

  .iconViewRecord img {
    width: 180px;
  }
  
  .iconViewRecordText {
    box-sizing: border-box;
    cursor: ponter;
    text-align: center; /* Center the text */
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    min-height: 20px; /* Minimum height to accommodate at least 2 lines of text */
    background-color: #ffffff;
  }

  .hoverOverlay {
    box-sizing: border-box;
    position: absolute;
    padding: 15px;
    left: -1px;
    top: 0px;
    width: 180px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Transparent white */
    color: black;
    overflow: auto;
}

.title {
    font-size: 1.1em; /* Adjust title size as needed */
    font-weight: bold;
    margin-bottom: 5px;
}

.staticText {
    padding-top: 15px;
    font-weight: bold;
}

.country {
    font-size: 1em; /* Adjust country size as needed */
}