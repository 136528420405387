.MobileQuerySummary {

}

.querySummary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-left: 20px;
}

.recordsFound {
  position: relative;
  margin-left: 20px;
  margin-bottom: 12px;
}

.recordsFoundCount {
  display: inline-block;
}

.recordsFoundCopyButton {
  position: absolute;
  display: inline-block;
  margin-left: 4px;
  margin-top: -6px;
}

.queryItem {
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
}

.removeButton {
  font-size: 9px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: darkgray;
}

.removeButton:hover {
  color: gray
}

.queryLabel {
  font-weight: 550;
  cursor: pointer;
}

.queryLabel:hover {
  text-decoration: underline;
}

.subQueryItem {
  padding: 2px 6px 6px 10px;
  border: 0.25px solid #e0e0e0;
  border-radius: 2px;
}

.subQueryItem:hover {
  text-decoration: underline;
  cursor: pointer;
}