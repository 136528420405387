.ZoomedImage {
  width: 100%;
  height: 100%;
}

.ZoomedImage :focus {
  outline: none;
}

.viewerBody {
  width: '100%';
  height: calc(100% - 40px);
  border: none;
}

.viewerToolbar {
  display: none; /* Hide the toolbar in mobile version*/
  overflow: hidden;
  text-align: center;
  width: '100%';
  height: 45px;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
}

.toolbarItem {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  text-align: center;
  line-height: 32px;
  border: 1px solid #ddd;
}

.toolbarItem:hover {
  background-color: #e0e0e0;
}

.filterPanel {
  position: fixed;
  left: 0px;
  top: 0px;
}

.popup {
  position: fixed;
  top: 0px;
  left: 100px;
  width: 300px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  z-index: 1000;
}

.popupHeader {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  margin-bottom: 10px;
}

.popupContent {
  padding: 10px;
}

.filterContainer {
  position: relative;
  margin-bottom: 15px;
}

.resetFilterContainer {
  margin-top: 30px;
  margin-bottom: 10px;
}

.redDot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  top: -4px;
  right: -4px;
}
 
.filterContainer {
  position: relative;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Align children to the start */
}

.grayscaleCheckbox, .grayscale {
  flex-grow: 0;     /* Prevent it from growing */
  flex-shrink: 0;   /* Prevent it from shrinking */
  margin: 0;
  padding: 0;
}

.grayscale {
  margin-left: 5px;  /* Adds a little spacing between the checkbox and the label */
}

.hidden {
  display: none;
}

.copyright {
  display: flex;
  color: white;
  background-color: #202124;
  padding: 5px;
  height: 30px;
  align-items: center;
}
