
.facetContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 4px;
    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: 6px;
    padding: 0 8px;  /* Add some padding to ensure content doesn't touch the edges */
}

.inputContainer {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0; /* To ensure it shrinks properly when space is limited */
    display: flex;
    align-items: center;
}

.mask {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 2px 8px;
    font-size: 14px;
    border: 0.25px solid #168e27;
    border-radius: 2px;
    height: 20px;
    outline: none;
    overflow: hidden;  /* Hide overflowing content */
}

.buttonClear, .sortButtons {
    flex-shrink: 0;  /* These items should not shrink */
}

  .mask::placeholder {
    color: #a0a0a0;
    font-size: 14px;
  }

  .buttonClear {
    font-size: 14px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: darkgray;
   }

  .content {
    padding: 0px 6px 0px 8px;
    overflow-y: auto;
    flex-grow: 1;
  }

    .facetName {
      display: flex;
      justify-content: space-between;
      font-weight: 550;
      margin-bottom: 8px;
      margin-left: 8px;
      margin-top: 0px;
      padding-bottom: 8px;
  }

  
   .selectedItems {
    padding-left: 8px;
    padding-bottom: 3px;
    margin-bottom: 8px;
   }

   .facetValue {
     cursor: pointer;
     display: inline-block;
   }

   .facetValue:hover {
    text-decoration: underline;
  }

  .clearAllContainer {
    display: block;
    margin-top: 4px;
    margin-bottom: 10px;
  }

  .clearAllIconContainer {
    display: inline-block;
    position: relative;
    width: 20px; /* Width of your checkbox */
    height: 20px; /* Height of your checkbox */
}

.clearAll {
    display: inline-block;
    position: absolute;
    margin-top: 6px;
    width: 10px;
    height: 10px;
    border: 0.9px solid black;
    background-color: white;
    border-radius: 1px;
}

.clearAll:nth-child(2) {
    top: 3px; /* Offset from the top */
    left: 3px; /* Offset from the left */
}

.clearAll:nth-child(3) {
    top: 6px;
    left: 6px;
}

.clearAllText {
  padding-left: 3px;
  display: inline-block;
  color: rgb(255,255,255,0.8);
}