/* EditorComponent.module.css */

.toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .matrixSelector {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
  }
  
  .matrixGrid {
    display: grid;
    grid-template-columns: repeat(10, 20px);
    grid-template-rows: repeat(10, 20px);
    gap: 2px;
  }
  
  .matrixRow {
    display: contents;
  }
  
  .matrixCell {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .matrixCellHovered {
    background: #007bff;
  }
  
  .matrixPreview {
    margin-top: 10px;
    text-align: center;
  }
 
.headerDropdown {
    margin-left: 8px;
    padding: 4px;
  }
  
  .draggablePopup {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px;
    padding: 10px;
  }
  
  .draggableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    padding: 5px 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .draggableHeader span {
    font-weight: bold;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .closeButton:hover {
    color: red;
  }
  
  .colorPicker {
    padding: 10px;
  }
  
  .toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .headerDropdown {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  
  