.filterContainer {
  position: relative;
  cursor: pointer;
  padding: 10px;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 65%;
}

.filterContainerSelected {
    border: 1px solid #585858;
}

.activeDot {
  content: '';
  position: absolute;
  top: -5px;
  right: -4px;
  width: 8px;
  height: 8px;
  background-color: rgb(255,0,0,0.7);
  border: 1px solid white;
  border-radius: 50%;
}
