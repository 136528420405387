.SimpleSearchBox {
  margin-top: 8px;
  display: flex;
  flex-grow: 1; /* allow it to grow and take up available space */
  gap: 5px;
  border-bottom: 1.5px solid #808080;
  padding-bottom: 10px;

}

.searchValueContainer {
  border: 0.0px solid darkgray;
  border-radius: 2px;
  height: 32px;
  flex-grow: 1; /* allow it to grow and take up available space */
  display: flex;
}

.searchValuesCount {
  cursor: pointer;
    display: inline-block;
    margin-left: 8px;
    margin-right: 0px;
    color: #808080;
    position: relative;
}

.searchValuesbutton {
  display: inline;
}

.textBox {
  height: 27px;
  padding-top: 3px;
  display: flex;
  flex-grow: 1; /* allow it to grow and take up available space */
}

.textBox input {
  font-size: 25px;
  width: 100%; /* take the full width */
}

.textBox input::placeholder {
  color: #c0c0c0;
  font-size: 20px;
}

.SimpleSearchTextbox {
  padding: 7px 10px;
  height: 20px;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.searchValues {
  position: absolute;
  display: none;
  border: 1px solid #c0c0c0;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 8px;
  width: 240px;
  min-height: 80px;
  z-index: 10; 
}

.searchValue {
  display: flex;            
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 0.5px solid #e8e8e8;
}

.searchValue:hover {
  background-color: #d0d0d0;
}

.searchValue.freetext {
}

.searchValue.facet {
}

.searchValue.typeahead {
}

.deleteValue {
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.typeAheadResults {
  position: fixed;
  border: 2px solid #d8d8d8;
  border-radius: 2px;
  background-color: #f8f8f8;
  min-width: 250px;
  z-index: 10;  
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.typeAheadItem {
  display: block;
  cursor: pointer;
  padding: 12px;
}

.typeAheadItem:hover {
  color: #f8f8f8;
  background-color: rgb(85, 120, 224);
}

.highlightedItem {
  color: #f8f8f8;
  background-color: #a0a0a0;; 
}

.redDot::before {
  display: none;
  content: "";
  width: 8px;
  height: 8px;
  background-color: red;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 19px;
  transform: translate(50%, -50%); /* This will position the dot to slightly go out of the box */
}
