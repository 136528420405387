.homePageBlock {
    display: inline-block;
    width: 350px;
    margin-right: 0px;
    text-align: center;
    background-color: #ffffff;
    cursor: pointer;
}

.homePageBlockImage {
    padding-top: 0px;
    vertical-align: top;
}

.homePageBlock img {
    width: 350px;
    height: 300px;
}

.homePageBlockText {
    text-decoration: none;
    color: #333;
    display: inline-block;
    vertical-align: top;
    padding: 18px;
    height: 25px;
    font-weight: 700;
}